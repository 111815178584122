<template>
  <v-app ref="app">
    <router-view></router-view>
    <v-snackbar
      v-model="globalSnack.show"
      :timeout="globalSnack.timeout || 6000"
      :color="globalSnack.type"
      >
        <span v-html="globalSnack.message"></span>
        <v-btn text @click="globalSnack.show = false">Close</v-btn>
    </v-snackbar>
	</v-app>
</template>

<script>
import { mapGetters } from "vuex";
import CopperContextService from '@/common/copper-context.service';

export default {
  data() {
    return {
      copperContextLoaded: false
    }
  },

  computed: {
    ...mapGetters([
      "globalSnack"
    ])
  },

  mounted() {
    //Change application height based on content
    new ResizeObserver(() => {
      CopperContextService.resizeApplication(this.$refs.app.$el.offsetHeight);
    }).observe(this.$refs.app.$el);
  }
}
</script>


<style lang="scss">
html {
  min-width: initial;
}

body, .v-application, .v-application--wrap, .container {
  background-color: white;
}

html {
  overflow-y: auto;
}

// /* Hide scrollbar for Chrome, Safari and Opera */
// body::-webkit-scrollbar {
//   display: none;
// }

// /* Hide scrollbar for IE, Edge and Firefox */
// body {
//   -ms-overflow-style: none;  /* IE and Edge */
//   scrollbar-width: none;  /* Firefox */
// }

.heading {
  font-size: 14px;
  text-align: center;
}
</style>